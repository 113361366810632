<template>
  <div class="card" v-if="session.films[0] && session.films[1]">
    <div class="card-images">
      <div class="left-image">
        <img :src="images.imageFilm1" alt="" />
        <div class="film-description">
          <div class="film-title">{{ session.films[0].title }}</div>
          <div class="film-start-time">Comienzo: <b>22:00</b></div>
          <div class="age-recommendation">
            Clasificación: <b>{{ session.films[0].age_recommendation }}</b>
          </div>
          <div class="url">
            Más Información:
            <a :href="session.films[0].more_info_url" target="blank"><b>Pulse Aquí</b></a>
          </div>
        </div>
      </div>
      <div class="right-image">
        <img :src="images.imageFilm2" alt="" />
        <div class="film-description">
          <div class="film-title">{{ session.films[1].title }}</div>
          <div class="film-start-time">
            Comienzo: <b>{{ startSecondFilm }}</b>
          </div>
          <div class="age-recommendation">
            Clasificación: <b>{{ session.films[1].age_recommendation }}</b>
          </div>
          <div class="url">
            Más Información:
            <a :href="session.films[1].more_info_url" target="blank"><b>Pulse Aquí</b></a>
          </div>
        </div>
      </div>
    </div>
    <hr class="rounded" />
    <div class="card-content">
      <span
        >Fechas: <b>{{ dates }}</b></span
      >
    </div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const dates = computed(() => {
      let filmsDates = [];

      props.session.dates.forEach(date => {
          filmsDates.push(window.luxon.DateTime.fromFormat(date.date, 'yyyy-LL-dd').toFormat('dd LLLL'))
      })
      return filmsDates.join(', ');
    });

    const images = computed(() => {
      const imageFilm1 = props.session.films[0].image.path;
      const imageFilm2 = props.session.films[1].image.path;
      return { imageFilm1, imageFilm2 };
    });

    const duration = computed(() => {
        return +props.session.films[0].running_time + +props.session.films[1].running_time;
    });

    const startSecondFilm = computed(() => {
      //luxon.DateTime.fromISO("2021-01-01T22:00", { zone: "Europe/Madrid" }).plus({minutes: 229}).toFormat('HH:mm')
      return window.luxon.DateTime.fromISO("2021-01-01T22:00", {
        zone: "Europe/Madrid",
      })
        .plus({ minutes: +props.session.films[0].running_time })
        .toFormat("HH:mm");
    });

    return { dates, duration, images, startSecondFilm };
  },
};
</script>

<style lang="scss" scoped>
.card {
  max-width: 800px;
  height: 100%;
  padding: 1em;
  background: #f8f8f8;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .card-images {
    display: flex;
    height: 70%;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: baseline;
    margin-bottom: 1em;

    .left-image {
      flex: 1;
      margin-right: 5px;
      height: 100%;

      img {
        object-fit: contain;
        width: 100%;
        max-height: 80%;
      }
    }

    .right-image {
      flex: 1;
      margin-left: 5px;
      height: 100%;

      img {
        object-fit: contain;
        width: 100%;
        max-height: 80%;
      }
    }

    .film-description {
      max-height: 20%;
      font-family: "Glegoo";
      font-size: 1em;

      .film-title {
        font-size: 1.2em;
        font-weight: 700;
      }

      .film-start-time {
        font-size: 0.8em;
      }

      .age-recommendation {
        font-size: 0.8em;
      }

      .url {
        font-size: 0.8em;

        a {
          color: rgb(121, 24, 24);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .rounded {
    border-top: 2px solid #bfbfbf;
    border-radius: 5px;
  }

  .card-content {
    display: flex;
    max-height: 30%;
    flex-wrap: wrap;
    margin-top: 1em;
    justify-content: space-around;
  }
}
</style>
