import { createApp } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faFilm,
  faCogs,
  faAddressBook,
  faVirus,
  faMapMarkerAlt,
  faPhone,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import App from "./App.vue";
import router from "./router";

import "@/assets/styles/global.scss";

library.add(
  faBars,
  faFilm,
  faCogs,
  faAddressBook,
  faVirus,
  faMapMarkerAlt,
  faPhone,
  faEnvelopeOpen,
  faFacebookSquare
);

const app = createApp(App);
app
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
