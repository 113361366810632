<template>
  <div class="layout">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.layout {
  width: 80%;
  margin: 20px auto;
}
</style>
