<template>
  <div class="sub-navbar">
    <div class="marquee">
      <p class="marquee-inner">
        <span class="premiere" v-if="textToShow.length > 3"
          >¡EN ESTRENO HOY!</span
        >
        <span class="today-movie">{{ textToShow }} </span>
      </p>
    </div>
    <div class="left-block"></div>
    <div class="right-block"></div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  props: {
    session: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const textToShow = computed(() => {
      const session = props.session;

      if (session && session.films && session.films.length === 2) {
        const filmsText = `${session.films[0].title} / ${session.films[1].title}`;
        return filmsText || "";
      }
      return "⌛️⏳";
    });

    return { textToShow };
  },
};
</script>

<style lang="scss" scoped>
.sub-navbar {
  background: #8fb5ba;
  overflow: hidden;

  .left-block {
    display: none;
  }

  .right-block {
    display: none;
  }
  .marquee {
    height: 30px;
    overflow: hidden;
    position: relative;
    background: black;

    &:hover {
      animation-play-state: paused;
    }

    .marquee-inner {
      position: absolute;
      width: max-content;
      height: 100%;
      margin: 0;
      text-align: center;
      padding-left: 100%;
      will-change: transform;
      animation: scroll 16s infinite linear;

      &:hover {
        animation-play-state: paused;
      }

      .premiere {
        white-space: nowrap;
        font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
        font-weight: 600;
        font-size: 1.2em;
        color: #ec3737;
        margin-right: 1em;
      }

      .today-movie {
        white-space: nowrap;
        color: #6ca06c;
        font-family: "DS-Digital";
        font-size: 1.5em;
        font-weight: 900;
      }
    }
  }

  /* Desktop menu */
  @media screen and (min-width: 700px) {
    .marquee {
      .marquee-inner {
      }
    }
    .left-block {
      display: block;
      position: absolute;
      width: 20%;
      height: 30px;
      bottom: 0;
      left: 0;
      background: #8fb5ba;
    }

    .right-block {
      display: block;
      position: absolute;
      width: 20%;
      height: 30px;
      bottom: 0;
      right: 0;
      background: #8fb5ba;
    }
  }

  @keyframes scroll {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
}
</style>
