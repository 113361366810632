import axios from 'axios';

export async function getTodaySession() {
    const session = await axios.get('/api/session-today');
    if(session.data.result.data) {
        return session.data.result.data
    } else {
        return null
    }
}

export async function getActiveSessions() {
    const session = await axios.get('/api/sessions');
    if(session.data.result.data) {
        return session.data.result.data
    } else {
        return null
    }
}
