import {createRouter, createWebHistory} from "vue-router";
import MovieBillBoard from "../views/MovieBillboard.vue";
import Services from "@/views/Services.vue";
import Contact from "@/views/Contact.vue";
import NotFound from "@/views/error/NotFound.vue";
import NetworkError from "@/views/error/NetworkError.vue";

const routes = [
    {
        path: "/admin"
    },
    {
        path: "/",
        name: "MovieBillBoard",
        component: MovieBillBoard,
    },
    {
        path: "/services",
        name: "Services",
        component: Services,
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/404/:resource",
        name: "404Resource",
        component: NotFound,
        props: true,
    },
    {
        path: "/network-error",
        name: "NetworkError",
        component: NetworkError,
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory('/'), // createWebHashHistory
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0};
        }
    },
});

export default router;
