<template>
  <div class="contact">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.616717221038!2d-1.2721023846883985!3d37.56409307979839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd64a14099e04039%3A0x3419a220e66681ac!2sCine%20Bah%C3%ADa!5e0!3m2!1ses!2ses!4v1627138462176!5m2!1ses!2ses"
      width="100%"
      height="60%"
      class="map"
      style="border: 0"
      allowfullscreen=""
      loading="lazy"
    ></iframe>

    <div class="info-card">
      <h1 class="title">Informacion</h1>
      <div class="address">
        <font-awesome-icon class="icon" icon="map-marker-alt" />
        <span
          ><a
            href="https://www.google.com/maps/place/Cine+Bah%C3%ADa/@37.5640931,-1.2721024,17z/data=!3m1!4b1!4m5!3m4!1s0xd64a14099e04039:0x3419a220e66681ac!8m2!3d37.5641183!4d-1.2699164s"
            >Vía Axial, S/N, 30860 Puerto de Mazarrón - Murcia</a
          ></span
        >
      </div>

      <div class="mail">
        <font-awesome-icon class="icon" icon="envelope-open" />
        <span><a href="mailto:mail@mail.com">cinesbahia@hotmail.com</a></span>
      </div>

      <div class="facebook">
        <font-awesome-icon class="icon" :icon="['fab', 'facebook-square']" />
        <span
          ><a
            href="https://es-la.facebook.com/CineBahiaMazarron/"
            target="blank"
            >Síguenos en Facebook!</a
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
a {
  color: rgb(121, 24, 24);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.contact {
  height: 80vh;
  iframe {
    padding: 0;
    margin: 0;
  }

  .info-card {
    width: 95%;
    margin: 1em auto;
    height: auto;
    padding: 1em;
    background: #f8f8f8;
    box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    .title {
      font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
      text-transform: uppercase;
      font-size: 2em;
      color: black;
      line-height: 24px;
      margin-bottom: 0.5em;
    }

    .icon {
      padding: 0;
      font-size: 2em;
      color: #ab2a3e;
    }

    .address {
      text-align: center;
      margin-bottom: 2em;

      span {
        margin-left: 1.5em;
      }
    }

    .facebook {
      text-align: center;

      span {
        margin-left: 1em;
      }
    }

    .mail {
      margin-bottom: 2em;
      text-align: center;

      span {
        margin-left: 1em;
      }
    }
  }
}
</style>
