<template>
    <Layout>
        <div class="movie-billboard">
            <Card
                v-for="session in sessions"
                :key="session.id"
                :session="session"
                class="card"
            ></Card>
        </div>
    </Layout>

    <div class="contact-section">
        <Contact></Contact>
    </div>
</template>

<script>
import Card from "@/components/FilmsCard.vue";
import Layout from "@/components/Layout.vue";
import Contact from "@/views/Contact.vue";
import {onMounted, ref} from "vue";
import {getActiveSessions} from "@/services/films.js";

export default {
    name: "MovieBillboard",
    components: {Card, Layout, Contact},

    setup() {
        const sessions = ref([]);

        onMounted(async () => {
            sessions.value = await getActiveSessions();
        });

        // const asessions = computed(() => {
        //     const allSessions = getFilmsBySession();
        //
        //     const data = [];
        //     allSessions.forEach((session) => {
        //         if (
        //             session.film_1.dates[session.film_1.dates.length - 1]
        //                 .split("-")
        //                 .join("") >=
        //             window.luxon.DateTime.now()
        //                 .setZone("Europe/Madrid")
        //                 .toFormat("yyyy-MM-dd")
        //                 .split("-")
        //                 .join("")
        //         ) {
        //             data.push(session);
        //         }
        //     });
        //
        //     return data;
        // });

        return {
            sessions,
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    margin: auto;
    margin-bottom: 2em;
}

.contact-section {
    margin-bottom: 2em;
}
</style>
