<template>
  <div class="not-found">
    <h1>Ha ocurrido un error</h1>
    <p>La página {{ resource }} que estás buscando no está por aquí.</p>
    <router-link :to="{ name: 'MovieBillBoard' }"
      >Vuelve a la página principal</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss"></style>
