<template>
  <Layout>
    <services-card />
  </Layout>
</template>

<script>
import ServicesCard from "@/components/ServicesCard.vue";
import Layout from "@/components/Layout.vue";
export default {
  components: { ServicesCard, Layout },
};
</script>

<style></style>
