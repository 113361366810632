<template>
  <nav ref="navbar">
    <ul class="menu">
      <li class="logo">
        <router-link class="nav-link" to="/">
          <Logo></Logo>
        </router-link>
      </li>

      <li class="item">
        <router-link @click="toggle" class="nav-link" to="/">
          <font-awesome-icon icon="film" class="nav-link-icon" />
          <span class="nav-link-text">Cartelera</span>
        </router-link>
      </li>
      <li class="item">
        <a href="#">
          <router-link @click="toggle" class="nav-link" to="/services">
            <font-awesome-icon icon="cogs" class="nav-link-icon" />
            <span class="nav-link-text">Servicios</span>
          </router-link>
        </a>
      </li>
      <li class="item">
        <a href="#">
          <router-link @click="toggle" class="nav-link" to="/contact">
            <font-awesome-icon icon="address-book" class="nav-link-icon" />
            <span class="nav-link-text">Contacto</span>
          </router-link>
        </a>
      </li>
      <li class="toggle">
        <a @click="toggle" href="#">
          <font-awesome-icon icon="bars" />
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
import { ref } from "vue";
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Logo,
  },
  setup() {
    const navbar = ref(null);

    const toggle = () => {
      const items = navbar.value.querySelectorAll(".item");
      const toggle = navbar.value.querySelector(".toggle");
      if (items.item(0).classList.contains("active")) {
        toggle.classList.remove("open");
        items.forEach((element) => {
          element.classList.remove("active");
        });
      } else {
        toggle.classList.add("open");
        items.forEach((element) => {
          element.classList.add("active");
        });
      }
    };

    return {
      navbar,
      toggle,
    };
  },
};
</script>

<style lang="scss" scoped>
nav {
  background: #8fb5ba;
  padding: 5px 20px;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  li {
    font-size: 16px;
    padding: 15px 5px;
    white-space: nowrap;
  }
}

.logo {
  flex: 2;
  a:hover {
    text-decoration: none;
  }
}

ul {
  list-style-type: none;
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.toggle {
  order: 1;
  transition: all 0.3s linear 0s;
  &.open {
    transform: rotate(90deg);
  }

  a {
    font-size: 40px;
  }
}

.item {
  width: 100%;
  font-family: Impact, Arial;
  letter-spacing: 1px;
  text-align: center;
  order: 2;
  display: none;

  &.active {
    display: block;
  }

  .router-link-active {
    svg {
      color: #ab2a3e;
    }
  }

  .nav-link-icon {
    font-size: 25px;
    margin-right: 10px;
  }

  .nav-link-text {
    font-size: 0.9em;
  }
}

/* Very small screen */
@media screen and (max-width: 205px) {
  .menu {
    justify-content: center;
  }
}

/* Desktop menu */
@media screen and (min-width: 700px) {
  .item {
    display: block;
    width: auto;
    order: 1;

    .nav-link-text {
      font-size: 1.2em;
    }
  }
  .toggle {
    display: none;
  }
  .logo {
    order: 0;
  }

  .menu {
    li {
      padding: 15px 10px;
    }
  }
}
</style>
