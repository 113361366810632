<template>
  <div class="logo">
    <img class="image" src="../assets/logo-image.png" alt="" />
    <img class="text" src="../assets/logo-text.png" alt="" />
  </div>
</template>

<style lang="scss" scoped>
.logo {
  display: flex;
  flex-flow: row nowrap;
  height: 55px;
  width: 110px;
  justify-content: center;
  align-items: center;
}

.image {
  height: 100%;
  width: 45%;
  flex-grow: 1;
  object-fit: contain;
}

.text {
  height: 100%;
  width: 55%;
  margin-left: -20px;

  align-self: flex-end;
  flex-grow: 1;
  object-fit: contain;
}
</style>
