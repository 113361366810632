<template>
  <div class="card">
    <div class="card-image">
      <img class="image" src="@/assets/images/cantina.jpg" alt="cantina" />
    </div>
    <div class="card-content">
      <h1 class="title">Cine Bahía Mazarrón</h1>
      <p>
        Disfruta durante todo el verano de <span>CINE BAHÍA</span>. Diviertete
        en nuestro cine al aire disfrutando de dos peliculones de estreno.
      </p>
      <br />
      <p>
        Las entradas se pueden comprar en taquilla a partir de las
        <b>21:00</b> el mismo día de la proyección. Precio de la entrada
        <b>6.50 €.</b>
      </p>

      <br />

      <p>
        Se proyectan dos películas todos los días, comenzando la sesión a las
        <b>22:00</b>. La segunda película comenzará 10 minutos después del fin
        de la primera. Compra tu entrada en la taquilla, pasa por nuestra
        cantina y sientate al aire libre para disfrutar de una bonita velada.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1em;
  background: #f8f8f8;
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  .card-image {
    height: auto;
    max-width: 300px;

    padding: 0.5em;

    .image {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-content {
    .title {
      color: #ab2a3e;
      font-family: Impact, Charcoal, sans-serif;
      letter-spacing: 1px;
      font-size: 1.2em;
      font-weight: 700;
      padding: 0.1em 0.5em;
      text-transform: uppercase;
      white-space: nowrap;
    }

    h2 {
      color: black;
      font-family: Gilroy, sans-serif;
      white-space: nowrap;
      font-size: 1.2em;
    }

    p {
      font-family: "Gil Sans", sans-serif;
      font-size: 0.8em;
      text-align: left;

      span {
        color: #ab2a3e;
      }
    }

    ul {
      margin: 0 0 0 2em;
      padding: 0;
      list-style-type: circle;
      text-align: left;
      li {
        span {
          font-size: 0.8em;
        }
      }
    }
  }

  /* Desktop menu */
  @media screen and (min-width: 700px) {
    .card-content {
      max-width: 50%;
    }
  }
}
</style>
