<template>
    <div class="menu">
        <nav-bar></nav-bar>
        <sub-navbar :session="todaySession"/>
    </div>
    <section>
        <router-view/>
    </section>
</template>

<script>
import NavBar from "@/components/navigation/NavBar.vue";
import SubNavbar from "@/components/navigation/SubNavbar.vue";
import {getTodaySession} from "@/services/films.js";
import {onMounted, ref} from 'vue';

export default {
    name: "App",
    components: {
        NavBar,
        SubNavbar,
    },
    setup() {
        window.luxon.Settings.defaultLocale = 'es'
        const todaySession = ref([]);

        onMounted(async () => {
            todaySession.value = await getTodaySession();
        });
        return {
            todaySession,
        };
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.menu {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
}

.covid-button {
    position: fixed;
    bottom: 10px;
    left: 10px;
}
</style>
